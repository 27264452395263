import { createContext, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import { hostname, scrapping_Host } from "src/HostName";
import { SignIn } from "src/redux/slices/userSignIn";
import Alert from "src/theme/overrides/Alert";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    localStorage.setItem("isloggedIn", true);
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },

  LOGOUT: (state, action) => {
    return {
      ...state,
      isAuthenticated: false,
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  scrappy_login: () => Promise.resolve(),
  scrappy_register: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [getRegisteredData, setRegisterData] = useState("");
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/api/account/my-account");
          const { user } = response.data;

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      username: email,
      password: password,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const callingapi = await fetch(`${hostname}agent/login`, requestOptions);
    const response = await callingapi.json();

    const user = response;

    localStorage.setItem("LoginData", JSON.stringify(user));
    if (response.status === true) {
      localStorage.setItem("LoginState", true);
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
    } else {
      return (
        <Alert severity="info" sx={{ mb: 3 }}>
          Invalid User
        </Alert>
      );
    }
  };
  const scrappy_login = async (email, password) => {
    var raw = JSON.stringify({
      userName: email,
      password: password,
    });
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };

    fetch(`${scrapping_Host}agentLogin`, options)
      .then((response) => response.json())
      .then((user) => {
        if (user.status === true) {
          localStorage.setItem("LoginState", true);
          localStorage.setItem("LoginData", JSON.stringify(user));

          dispatch({
            type: "LOGIN",
            payload: {
              user,
            },
          });
        } else {
          return (
            <Alert severity="info" sx={{ mb: 3 }}>
              Invalid User
            </Alert>
          );
        }
      })
      .catch((err) => console.error(err));
  };
  const scrappy_register = async (
    email,
    password,
    metaPermission,
    currencyPermission
  ) => {
    var meta = [metaPermission];
    var currency = [currencyPermission];

    var raw = JSON.stringify({
      userName: email,
      password: password,
      metaPermission: meta,
      currencyPermission: currency,
      role: "scrappy_user",
    });
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };
    fetch(`${scrapping_Host}createAgent`, options)
      .then((response) => response.json())
      .then((user) => setRegisterData(user))
      .catch((err) => console.error(err));
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/account/register", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    localStorage.setItem("LoginState", false);
    dispatch({ type: "LOGOUT", payload: false });
  };

  const resetPassword = () => {};

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        scrappy_login,
        scrappy_register,
        getRegisteredData,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
