
export const stop = (length) => {
  const stops =
    length === 1
      ? "Non-Stop"
      : length === 2
      ? "One-Stop"
      : length === 3
      ? "Two-Stop"
      : "Three-Stop";
  return stops;
};

export const TripSelect = [
  { id: 0, name: "Select Trip" },
  { id: 1, name: "Domestic" },
  { id: 2, name: "International" },
];

export const travelClass = [
  {
    id: 0,
    classname: "None",
    name: "Select Class",
  },
  {
    id: 1,
    classname: "ECONOMY",
    name: "Economy",
  },
  {
    id: 2,
    classname: "BUSINESS",
    name: "Business",
  },
  {
    id: 3,
    classname: "PREMIUM ECONOMY",
    name: "Premium Economy",
  },
  {
    id: 4,
    classname: "FIRST CLASS",
    name: "First Class",
  },
];

export const staticStop = [
  {
    id: 0,
    value: 0,
    name: "Direct",
  },
  {
    id: 1,
    value: 1,
    name: "1Stop",
  },
  {
    id: 2,
    value: 2,
    name: "2Stop",
  },
  {
    id: 3,
    value: -1,
    name: "ALL",
  },
];

export const TripType = [
  {
    id: 0,
    value: "1",
    name: "Oneway",
  },
  {
    id: 2,
    value: "2",
    name: "Roundway",
  },
  {
    id: 3,
    value: "1,2",
    name: "Both",
  },
];

export const PAX = [
  {
    id: 0,
    value: 1,
    name: 1,
  },
  {
    id: 1,
    value: 2,
    name: 2,
  },
  {
    id: 2,
    value: 3,
    name: 3,
  },
  {
    id: 3,
    value: 4,
    name: 4,
  },
  {
    id: 4,
    value: 0,
    name: "Any",
  },
];

export const agentruleDetail = (ruleData, aftercheckedairportMarkup) => {
  let name = {
    name: ruleData.name,
    gdsType: ruleData.gdsType,
    code: ruleData.code === "" ? "ALL" : ruleData.code,
    fareType: ruleData.fareType === "" ? "ALL" : ruleData.fareType,
    airlineMarkup: ruleData.airlineMarkup,
    fareTypeMarkup: ruleData.fareTypeMarkup,
    blockedAutoTicketAirline: ruleData.blockedAutoTicketAirline,
    blockedAirport: ruleData.blockedAirport,
    airportMarkup: aftercheckedairportMarkup,
    supStatus: ruleData.supStatus,
  };
  return name;
};
