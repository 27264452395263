import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { hostname } from "src/HostName";
import Alert from "src/theme/overrides/Alert";

const initialState = {
  value: 0,
  isLoading: false,
  isAuthenticated: false,
  userId: "",
  username: "",
  password: "",
};

const Login = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    SignIn(state, action) {
      state.isLoading = true;
      state.username = action.payload.data.username;
      state.password = action.payload.data.password;
      state.userId = action.payload.data.userid;
    },
    SignOut(state, action) {
      state.isLoading = action.payload.status;
      state.username = action.payload.username;
      state.password = action.payload.password;
      state.userId = action.payload.userid;
    },
    // walletBalance(state, action) {
    //   state.isLoading = false;
    //   state.userId = action.payload;
    // },
  },
});

export const { SignIn } = Login.actions;
export default Login.reducer;

// export const UserLogin = async (email, password) => {
//   return (dispatch) => {
//     console.log("APIHIRTTED");
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       user: 0,
//       username: email,
//       password: password,
//       role: "admin",
//     });
//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };
//     const callingapi = fetch(`${hostname}agent/login`, requestOptions);
//     const response = callingapi.json();

//     console.log("response:", response);

//     // if (response.status === true) {
//     //   console.log(user);
//     // } else {
//     //   return (
//     //     <Alert severity="info" sx={{ mb: 3 }}>
//     //       Invalid User
//     //     </Alert>
//     //   );
//     // }
//   };
// };

// const scrappy_login = async (email, password) => {
//   var raw = JSON.stringify({
//     userName: email,
//     password: password,
//   });
//   const options = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: raw,
//   };

//   fetch(`${scrapping_Host}agentLogin`, options)
//     .then((response) => response.json())
//     .then((user) => {
//       if (user.status === true) {
//         dispatch({
//           type: "LOGIN",
//           payload: {
//             user,
//           },
//         });
//       } else {
//         return (
//           <Alert severity="info" sx={{ mb: 3 }}>
//             Invalid User
//           </Alert>
//         );
//       }
//     })
//     .catch((err) => console.error(err));
// };
// const scrappy_register = async (
//   email,
//   password,
//   metaPermission,
//   currencyPermission
// ) => {
//   var meta = [metaPermission];
//   var currency = [currencyPermission];

//   var raw = JSON.stringify({
//     userName: email,
//     password: password,
//     metaPermission: meta,
//     currencyPermission: currency,
//     role: "scrappy_user",
//   });
//   const options = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: raw,
//   };
//   fetch(`${scrapping_Host}createAgent`, options)
//     .then((response) => response.json())
//     .then((user) => setRegisterData(user))
//     .catch((err) => console.error(err));
// };
