import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { motion } from "framer-motion";
import React, { useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { varFadeInRight } from "../animate";
import { Button } from "@material-ui/core";

import { useSnackbar } from "notistack5";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { hotelhostname } from "src/HostName";
const PendingBookingList = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const [savedOrderId, setSavedOrderId] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [selectpage, setselectPage] = useState(15);
  const list = [
    {
      id: 14,
      name: "Minutes Ago",
    },
    { id: 10, name: "OrderId" },
    { id: 0, name: "BookingId" },
    { id: 1, name: "Status" },
    { id: 11, name: "BookingDate" },
    { id: 4, name: "Amount" },
    { id: 5, name: "CheckIn" },
    { id: 6, name: "CheckOut" },
    { id: 7, name: "Pax" },
    { id: 8, name: "Night" },
    { id: 9, name: "Room" },
    { id: 13, name: "Payment Status" },
    {
      id: 12,
      name: "Control Status",
    },
  ];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const optionList = [15, 30, 45, 60];
  const changeBookingStatus = async () => {
    try {
      const options = {
        method: "POST",
        url: `${hotelhostname}/confirm/booking/status`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { orderId: savedOrderId },
      };
      const data = await axios.request(options);
      if (data?.data?.status === true) {
        setOpen(false);
        enqueueSnackbar(
          `Order id ${savedOrderId} is confirmed you can check in confirmed booking table`,
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(
          `Order id ${savedOrderId} is not confirmed due to some technical error.`,
          {
            variant: "error",
          }
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div>
      {" "}
      <TableContainer className="tableairp-responsiveBooking ">
        <Table className="table-airp">
          <TableHead>
            <TableRow>
              {list.map((item, index) => (
                <TableCell key={index}>{item.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.slice(page * selectpage - selectpage, page * selectpage)
              .map((item, index) => {
                return (
                  <>
                    <TableRow key={index}>
                      <TableCell>{moment(item.createdAt).fromNow()}</TableCell>
                      <TableCell>{item.orderId}</TableCell>
                      <TableCell
                        //   onClick={() => handleOpen(item.id)}
                        style={{ color: "green", cursor: "pointer" }}
                      >
                        {item.bookingId}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            item.bookingStatus === "confirm"
                              ? "green"
                              : item.bookingStatus === "cancelled"
                              ? "Red"
                              : "",
                        }}
                      >
                        {item.bookingStatus}
                      </TableCell>
                      <TableCell>
                        {moment(item.createdAt).format("DD MMM , YYYY")} ,&nbsp;
                        {moment(item.createdAt).format("hh:mm A")}
                      </TableCell>
                      {/* <TableCell>{item.gdsType}</TableCell> */}
                      {/* <TableCell>{item.key}</TableCell> */}
                      <TableCell className="d-flex align-items-center">
                        {item.bookingAmount?.grandTotal ? (
                          <>{item.bookingAmount?.currencyCode}</>
                        ) : null}
                        &nbsp;
                        <h6 className="mb-0">
                          {item.bookingAmount?.grandTotal}
                        </h6>
                      </TableCell>
                      <TableCell>
                        {moment(item.checkIn).format("DD MMM , YYYY")}
                      </TableCell>
                      <TableCell>
                        {moment(item.checkOut).format("DD MMM , YYYY")}
                      </TableCell>
                      <TableCell>{item?.paxDetails.length}</TableCell>
                      <TableCell>{item?.totalNight}</TableCell>
                      <TableCell>{item?.rooms}</TableCell>
                      <TableCell style={{ textTransform: "capitalize" }}>
                        {item?.paymentResponse?.STATUS === "TXN_SUCCESS" ||
                        item?.paymentResponse?.payment_status === "paid"
                          ? "Paid"
                          : "Unpaid"}
                      </TableCell>
                      <TableCell>
                        {
                          <motion.div variants={varFadeInRight}>
                            <Button
                              size="medium"
                              variant="contained"
                              disabled={item?.bookingStatus !== "hold"}
                              onClick={() => {
                                handleOpen();
                                setSavedOrderId(item?.orderId);
                              }}
                            >
                              Change Status ({item.bookingStatus})
                            </Button>
                          </motion.div>
                        }
                      </TableCell>
                    </TableRow>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Are you sure you want to change the booking status
                          from hold to comfirm?
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "12px",
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            disabled={item?.bookingStatus !== "hold"}
                            onClick={() => handleClose()}
                          >
                            No
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            disabled={item?.bookingStatus !== "hold"}
                            onClick={() => changeBookingStatus(item?.orderId)}
                          >
                            Yes
                          </Button>
                        </div>
                      </Box>
                    </Modal>
                  </>
                );
              })}
          </TableBody>
        </Table>
        {data?.length > 15 ? (
          <div className="d-flex justify-content-end align-items-center">
            <div>{`Total Booking ${data?.length}`}</div>
            <div className="d-flex flex-row-reverse m-3">
              <ChevronRightIcon
                onClick={() =>
                  page < data?.length / selectpage
                    ? setPage((pre) => pre + 1)
                    : null
                }
                className="cursor-pointer"
              />
              {page} -&nbsp;
              {Math.ceil(data?.length / selectpage)} Page
              <KeyboardArrowLeftIcon
                onClick={() => (page > 1 ? setPage((pre) => pre - 1) : null)}
                className="cursor-pointer"
              />
              <select
                name="selectpage"
                id="selectpage"
                value={selectpage}
                onChange={(e) => setselectPage(e.target.value)}
                className="border border-2 rounded ml-2"
              >
                {optionList.map((value, i) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              Row Per Page
            </div>
          </div>
        ) : null}
      </TableContainer>
    </div>
  );
};

export default PendingBookingList;
