import axios from "axios";
import { useEffect, useState } from "react";

export const useGetHotelBoooking = (url) => {
  const [result, setResult] = useState({
    loading: false,
    error: null,
    data: {},
  });
  useEffect(() => {
    (async () => {
      try {
        setResult({ ...result, loading: true });
        const data = await axios.get(url);
        if (data.data.baseResponse.status) {
          setResult({ ...result, data: data.data });
        } else {
          setResult({ ...result });
        }
      } catch (error) {
        console.log("error", error.message);
        setResult({ ...result, error: error.message });
      }
    })();
  }, []);
  return result;
};
export const useGetPendingHotelBooking = (url) => {
  const [result, setResult] = useState({
    loading: false,
    error: null,
    data: {},
  });
  useEffect(() => {
    (async () => {
      try {
        setResult({ ...result, loading: true });
        const data = await axios.get(url);
        if (data?.data?.status === true) {
          setResult({ ...result, data: data.data });
        } else {
          setResult({ ...result });
        }
      } catch (error) {
        console.log("error", error.message);
        setResult({ ...result, error: error.message });
      }
    })();
  }, []);
  return result;
};
export const useFullBookingHistory = (url) => {
  const [open, setOpen] = useState(false);
  const [apiresult, setApiResult] = useState({
    data: null,
    loading: false,
    error: null,
  });
  const handleOpen = async (item) => {
    const APiurl = `${url}/${item}`;
    setOpen(true);
    try {
      setApiResult({ ...apiresult, loading: true });
      const result = await axios.get(`${APiurl}`);
      setApiResult({ ...apiresult, data: result.data });
    } catch (error) {
      console.log("error", error);
      setApiResult({ ...apiresult, error: error.message });
    }
  };
  return {
    open: open,
    apiresult: apiresult,
    handleOpen: handleOpen,
    setOpen: setOpen,
  };
};

export const useGetHotelRule = (url, userid) => {
  const [result, setResult] = useState({
    loading: false,
    data: null,
    error: null,
  });
  useEffect(() => {
    (async () => {
      try {
        setResult({ ...result, loading: true });
        const Result = await axios.get(`${url}/${userid}`);
        setResult({ ...result, data: Result.data });
      } catch (error) {
        setResult({ ...result, error: error.message });
        console.error("error", error.message);
      }
    })();
  }, [userid]);
  return result;
};
