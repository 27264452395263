import React from "react";
import { Link } from "react-router-dom";

const ErrorPages = ({ title = "Technical Error" }) => {
  return (
    <div className="technical-errorp py-5 my-3 text-center">
      <div className="container text-center">
        <span className="d-block kytravo-h1 font-bold mb-1  text-danger">
          {" "}
          Uh Oh!
        </span>
        <div className="mb-3 kytravo-h2  font-semibold">{title}</div>
        <img
          src="/static/brand/not-found-error.gif"
          className="technical-thumb"
        />
        <p className="mt-3 mb-4 text-muted">
          Looks like you've entered a wrong url, Please check the url or try
          searching again
        </p>
      </div>
    </div>
  );
};

export default ErrorPages;
