import axios from "axios";
import { hostname } from "src/HostName";
import { agentruleDetail } from "src/utils/stop";

export const updateFarerule = async (modalData, ads, handleClose) => {
  const Data = {
    _id: modalData._id,
    provider: ads,
    blockedAirportSource: modalData.blockedAirportSource,
    rule: modalData.rule,
    amount: modalData.amount,
    fromDate: modalData.fromDate,
    toDate: modalData.toDate,
    blockedCountrySource: modalData.blockedCountrySource,
    noOfPAXSource: modalData.noOfPAXSource,
    tripTypeSource: modalData.tripTypeSource,
    convFee: Number(modalData.convFee),
    isactive: modalData.isactive,
  };

  const options = {
    method: "PATCH",
    url: `${hostname}update/farerule/info`,
    headers: { "Content-Type": "application/json" },
    data: Data,
  };
  axios
    .request(options)
    .then(function (response) {
      alert("Fare Rule updated");
      handleClose();
    })
    .catch(function (error) {
      console.error("error");
    });
};

export const handleDeleteairportMarkup = async (agentData, item, ruleData) => {
  const provider = agentData.apiprovider.filter(
    (item) => item.gdsType !== ruleData.gdsType
  );
  var afterDelete = ruleData.airportMarkup.filter((it, i) => {
    if (it.ruleName !== item.ruleName) {
      return it;
    }
  });
  const remainData = agentruleDetail(ruleData, afterDelete);
  const ApiProvider = provider.concat(remainData);
  if (ApiProvider) {
    const Data = {
      _id: agentData._id,
      provider: ApiProvider,
      blockedAirportSource: agentData.blockedAirportSource,
      rule: agentData.rule,
      amount: agentData.amount,
      fromDate: agentData.fromDate,
      toDate: agentData.toDate,
      blockedCountrySource: agentData.blockedCountrySource,
      noOfPAXSource: agentData.noOfPAXSource,
      tripTypeSource: agentData.tripTypeSource,
      convFee: Number(agentData.convFee),
      isactive: agentData.isactive,
    };

    try {
      const result = await axios.patch(`${hostname}update/farerule/info`, Data);
      if (result.data.status) {
        alert("AirportMarkup Rule Deleted Successfully");
      }
    } catch (error) {
      console.log("error", error.message);
      alert("AirportMarkup Rule Not Deleted");
    }
  }
};

export const handlemultipleDelete = async (
  // checkedairportMarkup,
  // allAirportMarkup,
  // ruleData,
  // agentData
  checkedairportMarkupRuleName,
  userid,
  gdsType
) => {
  // const aftercheckedairportMarkup = allAirportMarkup.filter(
  //   ({ ruleName: ruleName1 }) =>
  //     !checkedairportMarkup.some(
  //       ({ ruleName: ruleName2 }) => ruleName2 === ruleName1
  //     )
  // );
  // const provider = agentData.apiprovider.filter(
  //   (item) => item.gdsType !== ruleData.gdsType
  // );
  // const remainData = agentruleDetail(ruleData, aftercheckedairportMarkup);
  // const ApiProvider = provider.concat(remainData);
  // if (ApiProvider) {
  //   const Data = {
  //     _id: agentData._id,
  //     provider: ApiProvider,
  //     blockedAirportSource: agentData.blockedAirportSource,
  //     rule: agentData.rule,
  //     amount: agentData.amount,
  //     fromDate: agentData.fromDate,
  //     toDate: agentData.toDate,
  //     blockedCountrySource: agentData.blockedCountrySource,
  //     noOfPAXSource: agentData.noOfPAXSource,
  //     tripTypeSource: agentData.tripTypeSource,
  //   };
  //   try {
  //     const result = await axios.patch(`${hostname}update/farerule/info`, Data);
  //     if (result.data.status) {
  //       alert("AirportMarkup Rule Deleted Successfully");
  //     }
  //   } catch (error) {
  //     console.log("error", error.message);
  //     alert("AirportMarkup Rule Not Deleted");
  //   }
  // }
  const options = {
    method: "DELETE",
    url: `${hostname}fare_rule/airportMarkupDelete`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userid: Number(userid),
      gdsType: Number(gdsType),
      ruleName: checkedairportMarkupRuleName,
    },
  };
  try {
    const result = await axios.request(options);
    if (result.data.status === 1) {
      alert(result.data.message);
    }
  } catch (error) {
    console.log("error", error.message);
    alert("AirportMarkup Rule Not Deleted");
  }
};

export const disableProvider = async (singleagent, check) => {
  if (singleagent) {
    const bodyData = {
      _id: singleagent._id,
      provider: singleagent.apiprovider,
      blockedAirportSource: singleagent.blockedAirportSource,
      rule: singleagent.rule,
      amount: singleagent.amount,
      fromDate: singleagent.fromDate,
      toDate: singleagent.toDate,
      blockedCountrySource: singleagent.blockedCountrySource,
      noOfPAXSource: singleagent.noOfPAXSource,
      tripTypeSource: singleagent.tripTypeSource,
      isactive: check,
      convFee: Number(singleagent.convFee),
    };
    try {
      const data = await axios.patch(
        `${hostname}update/farerule/info`,
        bodyData
      );
      return data.data;
    } catch (error) {
      console.log(error.message);
      return error.message;
    }
  }
};
