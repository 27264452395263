import PropTypes from "prop-types";
import { random, orderBy } from "lodash";
import { Icon } from "@iconify/react";
import heartFill from "@iconify/icons-eva/heart-fill";
import outlineFlightTakeoff from "@iconify/icons-ic/outline-flight-takeoff";
import trophyFilled from "@iconify/icons-ant-design/trophy-filled";
// material
import { alpha, styled } from "@material-ui/core/styles";
import {
  Box,
  Stack,
  Card,
  Avatar,
  CardHeader,
  Typography,
} from "@material-ui/core";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
import mockData from "../../../utils/mock-data";

// ----------------------------------------------------------------------

const MOCK_AUTHORS = [...Array(4)].map((_, index) => ({
  id: mockData.id(index),
  name: mockData.name.allRoutes(index),
  avatar: mockData.image.avatar(index),
  favourite: random(9999, 19999),
  bookCount: random(100, 500),
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
}));

// ----------------------------------------------------------------------

AuthorItem.propTypes = {
  author: PropTypes.shape({
    avatar: PropTypes.string,
    favourite: PropTypes.number,
    name: PropTypes.string,
  }),
  index: PropTypes.number,
};

function AuthorItem({ author, index }) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Avatar alt={author.name} src="../static/images/plain.png" />
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{author.name}</Typography>
        <Typography
          variant="caption"
          sx={{
            mt: 0.5,
            display: "flex",
            alignItems: "center",
            color: "text.secondary",
          }}
        >
          <Box
            component={Icon}
            icon={outlineFlightTakeoff}
            sx={{ width: 16, height: 16, mr: 0.5 }}
          />
          {fShortenNumber(author.bookCount)}
        </Typography>
      </Box>

      <IconWrapperStyle
        sx={{
          ...(index === 1 && {
            color: "info.main",
            bgcolor: (theme) => alpha(theme.palette.info.main, 0.08),
          }),
          ...(index === 2 && {
            color: "error.main",
            bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
          }),
        }}
      >
        <Icon icon={trophyFilled} width={20} height={20} />
      </IconWrapperStyle>
    </Stack>
  );
}

export default function AppTopAuthors() {
  const displayAuthor = orderBy(MOCK_AUTHORS, ["favourite"], ["desc"]);

  return (
    <Card>
      <CardHeader title="Top Routes" />
      <Stack spacing={3} sx={{ p: 3 }}>
        {displayAuthor.map((author, index) => (
          <AuthorItem key={author.id} author={author} index={index} />
        ))}
      </Stack>
    </Card>
  );
}
