import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const choiceexcleSiteDownLoad = (checkedairportMarkupdata, user) => {
  const BulkMarkup =
    user.role === "admin"
      ? checkedairportMarkupdata.map((item) => {
          return {
            userid: item.userid,
            tripType:
              item.tripType === "1"
                ? "Oneway"
                : item.tripType === "2"
                ? "Roundway"
                : "Both",
            noOfPax: item.noOfPAX === 0 ? "Any" : item.noOfPAX,
            originAirport: item.From,
            originCountry: item.originCountry,
            destinationAirport: item.To,
            destinationCountry: item.destinationCountry,
            amount: item.Amount,
            capAmount: item.capAmount,
            OutBoundStartEnd: item.RuleDateFrom,
            // travelEnd: item.RuleDateTo,
            cabinSegment: item.cabinSegment,
            airCode: item.AirCode,
            flightNo: item.flightNo,
            flightNoReturn: item.flightNoReturn,
            minAmount: item.minAmount,
            maxAmount: item.maxAmount,
            stops:
              item.stops === 0
                ? "Direct"
                : item.stops === 1
                ? "1Stop"
                : item.stops === 2
                ? "2Stop"
                : "ALL",
            ruleName: item.ruleName,
            cappingDays: item.cappingDays,
            cabinSegmentInbound: item.cabinSegmentInbound,
            arrTimeMarkup: item.arrTimeMarkup,
            depTimeMarkup: item.depTimeMarkup,
            cabinClass: item.cabinClass,
            markupType: item.markupType,
            opaqueData: item.opaqueData,
            InBoundStartEnd: item.RuleDateFromIn,
            // RuleDateToIn: item.RuleDateToIn,
            FareType: item.FareType,
            dGap: item.dGap,
            dGapEnd: item.dGapEnd,
            operatedBy: item.operatedBy,
          };
        })
      : checkedairportMarkupdata
          .filter((ite) => ite.userid === user.userid)
          .map((item) => {
            return {
              userid: item.userid,
              tripType:
                item.tripType === "1"
                  ? "Oneway"
                  : item.tripType === "2"
                  ? "Roundway"
                  : "Both",
              noOfPax: item.noOfPAX === 0 ? "Any" : item.noOfPAX,
              originAirport: item.From,
              originCountry: item.originCountry,
              destinationAirport: item.To,
              destinationCountry: item.destinationCountry,
              amount: item.Amount,
              capAmount: item.capAmount,
              OutBoundStartEnd: item.RuleDateFrom,
              // travelEnd: item.RuleDateTo,
              cabinSegment: item.cabinSegment,
              airCode: item.AirCode,
              flightNo: item.flightNo,
              flightNoReturn: item.flightNoReturn,
              minAmount: item.minAmount,
              maxAmount: item.maxAmount,
              stops:
                item.stops === 0
                  ? "Direct"
                  : item.stops === 1
                  ? "1Stop"
                  : item.stops === 2
                  ? "2Stop"
                  : "ALL",
              ruleName: item.ruleName,
              cappingDays: item.cappingDays,
              cabinSegmentInbound: item.cabinSegmentInbound,
              arrTimeMarkup: item.arrTimeMarkup,
              depTimeMarkup: item.depTimeMarkup,
              cabinClass: item.cabinClass,
              markupType: item.markupType,
              opaqueData: item.opaqueData,
              InBoundStartEnd: item.RuleDateFromIn,
              // RuleDateToIn: item.RuleDateToIn,
              FareType: item.FareType,
              dGap: item.dGap,
              dGapEnd: item.dGapEnd,
              operatedBy: item.operatedBy,
            };
          });
  const ws = XLSX.utils.json_to_sheet(BulkMarkup);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, "BulkAirportMarkup" + fileExtension);
};
