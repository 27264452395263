// routes
import { PATH_DASHBOARD, PATH_PAGE } from "../../routes/paths";
// components
import Label from "../../components/Label";
import SvgIconStyle from "../../components/SvgIconStyle";
import useAuth from "src/hooks/useAuth";

// ----------------------------------------------------------------------
const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  home: getIcon("ic_home"),
  rule: getIcon("ic_rules"),
  topUp: getIcon("ic_topup"),
  transaction: getIcon("ic_transaction"),
  airport: getIcon("ic_airport"),
  home: getIcon("ic_home"),
  wallet: getIcon("ic_wallet"),
  bookingConfirmed: getIcon("ic_booking-confirmed"),
  allData: getIcon("ic_allData"),
  add: getIcon("ic_add"),
};

const sidebarConfig = [
  // GENERAL
  {
    subheader: "Dashboard",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.root,
        icon: ICONS.home,
      },
      {
        title: "Meta Management",
        path: PATH_DASHBOARD.general.get_meta,
        icon: ICONS.bookingConfirmed,
      },
      // {
      //   title: "booking",
      //   path: PATH_DASHBOARD.general.booking,
      //   icon: ICONS.booking,
      // },
    ],
  },

  // ----------------------------------------------------------------------
  {
    subheader: "Bookings",
    items: [
      {
        title: "Book Flights",
        path: PATH_PAGE.flightBooking,
        icon: ICONS.airport,
      },

      // {
      //   title: "Cancellations",
      //   path: PATH_PAGE.comingSoon,
      //   icon: ICONS.banking,
      // },
      // {
      //   title: "banking",
      //   path: PATH_DASHBOARD.general.banking,
      //   icon: ICONS.banking,
      // },
      // {
      //   title: "booking",
      //   path: PATH_DASHBOARD.general.booking,
      //   icon: ICONS.booking,
      // },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  // subheader: "Reports",
  // items: [
  // MANAGEMENT : USER
  // {
  //   title: "Customers",
  //   path: PATH_DASHBOARD.user.root,
  //   path: PATH_PAGE.comingSoon,
  //   icon: ICONS.user,
  // },
  // {
  //   title: "Orders",
  //   path: PATH_DASHBOARD.blog.root,
  //   path: PATH_PAGE.comingSoon,
  //   icon: ICONS.booking,
  // },
  // {
  //   title: "Analytics",
  //   path: PATH_DASHBOARD.general.analytics,
  //   path: PATH_PAGE.comingSoon,
  //   icon: ICONS.analytics,
  // },

  // MANAGEMENT : E-COMMERCE
  // {
  //   title: "Wallet",
  //   path: PATH_DASHBOARD.eCommerce.root,
  //   icon: ICONS.cart,
  //   children: [
  //     { title: "shop", path: PATH_DASHBOARD.eCommerce.shop },
  //     { title: "product", path: PATH_DASHBOARD.eCommerce.productById },
  //     { title: "list", path: PATH_DASHBOARD.eCommerce.list },
  //     { title: "create", path: PATH_DASHBOARD.eCommerce.newProduct },
  //     { title: "edit", path: PATH_DASHBOARD.eCommerce.editById },
  //     { title: "checkout", path: PATH_DASHBOARD.eCommerce.checkout },
  //     { title: "invoice", path: PATH_DASHBOARD.eCommerce.invoice },
  //   ],
  // },

  // MANAGEMENT : BLOG
  // {
  //   title: "Analytics",
  //   path: PATH_DASHBOARD.blog.root,
  //   icon: ICONS.blog,
  //   children: [
  //     { title: "posts", path: PATH_DASHBOARD.blog.posts },
  //     { title: "post", path: PATH_DASHBOARD.blog.postById },
  //     { title: "new post", path: PATH_DASHBOARD.blog.newPost },
  //   ],
  // },
  // ],
  // },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: "Wallet",
    items: [
      {
        title: "Balance",
        //  path: PATH_DASHBOARD.mail.root,
        path: PATH_PAGE.comingSoon,
        icon: ICONS.wallet,
      },
      {
        title: "Top Up",
        path: PATH_DASHBOARD.TopUpMain.topup,
        icon: ICONS.topUp,
      },
      {
        title: "Transactions",
        path: PATH_DASHBOARD.general.chooseAgent,
        icon: ICONS.transaction,
      },
    ],
  },
  {
    subheader: "Fare Rule",
    items: [
      {
        title: "All Fare Rule",
        path: PATH_DASHBOARD.farerule.checkAgentRules,
        icon: ICONS.rule,
      },
      // {
      //   title: "Add fare rule",
      //   path: PATH_DASHBOARD.farerule.farerule,
      //   icon: ICONS.rule,
      // },
    ],
  },
  {
    subheader: "Booking History",
    items: [
      {
        title: "Hotels Booking ",
        path: PATH_DASHBOARD.BookingHistory.hotelsBooking,
        icon: ICONS.rule,
      },
      {
        title: "Flight Booking ",
        path: PATH_DASHBOARD.BookingHistory.FlightBooking,
        icon: ICONS.rule,
      },
    ],
  },

  // {
  //   subheader: "Scrapping Data Management",
  //   items: [
  //     {
  //       title: "View all data",
  //       path: PATH_DASHBOARD.general.scrap_all_data,
  //       icon: ICONS.allData,
  //     },
  //     {
  //       title: "Add data",
  //       path: PATH_DASHBOARD.general.add_Iternary,
  //       icon: ICONS.add,
  //     },
  //     {
  //       title: "Add bulk data",
  //       path: PATH_DASHBOARD.general.add_bulk_data,
  //       icon: ICONS.add,
  //     },
  //   ],
  // },
  {
    subheader: "Approved Requests",
    items: [
      {
        title: "Requests",
        path: PATH_DASHBOARD.general.approval,
        icon: ICONS.rule,
      },
    ],
  },
  {
    subheader: "About Us",
    items: [
      {
        title: "Privacy Policy",
        path: PATH_DASHBOARD.aboutus.privacy,
        icon: ICONS.rule,
      },
      {
        title: "Terms and Conditions",
        path: PATH_DASHBOARD.aboutus.terms,
        icon: ICONS.rule,
      },
    ],
  },
];

export default sidebarConfig;
