// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  Scrappy_Login: path(ROOTS_AUTH, "/scrappy-login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  flightBooking: "/flightbooking",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  controlBookingStatus: "/control-booking-status",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/finalpage",
  page500: "/500",
  components: "/components",
  topup: "/topup",
  farerule: "/farerule",
  trans: "/trans",
  ApplyRule: "/applyrule",
  AppliedRule: "/AppliedRule",
  bookingsHistory: "/bookingsHistory",
  privacyPolicy: "/privacyPolicy",
  terms: "/terms_and_condittermsAndConditonsions",
  approval: "/approval",
  checkAgentRules: "/checkAgentRules",
  hotelsBookingList: "/hotelsBookingList",
  flightBookingList: "/flightBookingList",
  scrap_all_data: "scrap-all-data",
  add_Iternary: "add-Iternary",
  add_bulk_data: "add-bulk-data",
  Scrappy_Login: "/Scrappy-Login",
  add_scrappy_user: "/add-scrappy-user",
  view_scrappy_user: "/view-scrappy-user",
  get_meta: "/get-meta",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
    banking: path(ROOTS_DASHBOARD, "/banking"),
    booking: path(ROOTS_DASHBOARD, "/booking"),
    bookingsHistory: path(ROOTS_DASHBOARD, "/bookingsHistory"),
    approval: path(ROOTS_DASHBOARD, "/approval"),
    chooseAgent: path(ROOTS_DASHBOARD, "/chooseAgent"),
    cancellations: path(ROOTS_DASHBOARD, "/cancellations"),
    scrap_all_data: path(ROOTS_DASHBOARD, "/scrap-all-data"),
    add_Iternary: path(ROOTS_DASHBOARD, "/add-Iternary"),
    add_bulk_data: path(ROOTS_DASHBOARD, "/add-bulk-data"),
    add_scrappy_user: path(ROOTS_DASHBOARD, "/add-scrappy-user"),
    view_scrappy_user: path(ROOTS_DASHBOARD, "/view-scrappy-user"),
    get_meta: path(ROOTS_DASHBOARD, "/get-meta"),
  },
  Flight: {
    root: path(ROOTS_DASHBOARD, "/dashboard/app"),
    flightbooking: path(ROOTS_DASHBOARD, "/flightbooking"),
  },
  history: {
    root: path(ROOTS_DASHBOARD, "/dashboard/app"),
    book: path(ROOTS_DASHBOARD, "/bookingsHistory"),
  },
  aboutus: {
    root: path(ROOTS_DASHBOARD, "/dashboard/app"),
    privacy: path(ROOTS_DASHBOARD, "/privacyPolicy"),
    terms: path(ROOTS_DASHBOARD, "/termsAndConditons"),
  },
  farerule: {
    root: path(ROOTS_DASHBOARD, "/dashboard/app"),
    farerule: path(ROOTS_DASHBOARD, "/farerule"),
    ApplyRule: path(ROOTS_DASHBOARD, "/applyrule"),
    AppliedRule: path(ROOTS_DASHBOARD, "/AppliedRule"),
    checkAgentRules: path(ROOTS_DASHBOARD, "/checkAgentRules"),
  },
  BookingHistory: {
    root: path(ROOTS_DASHBOARD, "/dashboard/app"),
    hotelsBooking: path(ROOTS_DASHBOARD, "/hotelsBookingList"),
    FlightBooking: path(ROOTS_DASHBOARD, "/flightBookingList"),
  },
  AccTrans: {
    root: path(ROOTS_DASHBOARD, "/dashboard/app"),
    trans: path(ROOTS_DASHBOARD, "/trans"),
    agentLedger: path(ROOTS_DASHBOARD, "/agentLedger"),
  },
  TopUpMain: {
    root: path(ROOTS_DASHBOARD, "/dashboard/app"),
    topup: path(ROOTS_DASHBOARD, "/topup"),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, "/mail"),
    all: path(ROOTS_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, "/chat"),
    new: path(ROOTS_DASHBOARD, "/chat/new"),
    conversation: path(ROOTS_DASHBOARD, "/chat/:conversationKey"),
  },
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    newUser: path(ROOTS_DASHBOARD, "/user/new"),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, "/e-commerce"),
    shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
    product: path(ROOTS_DASHBOARD, "/e-commerce/product/:name"),
    productById: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-air-force-1-ndestrukt"
    ),
    list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
    newProduct: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
    editById: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-blazer-low-77-vintage/edit"
    ),
    checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
    invoice: path(ROOTS_DASHBOARD, "/e-commerce/invoice"),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, "/blog"),
    posts: path(ROOTS_DASHBOARD, "/blog/posts"),
    post: path(ROOTS_DASHBOARD, "/blog/post/:title"),
    postById: path(
      ROOTS_DASHBOARD,
      "/blog/post/apply-these-7-secret-techniques-to-improve-event"
    ),
    newPost: path(ROOTS_DASHBOARD, "/blog/new-post"),
  },
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
