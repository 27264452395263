import React from "react";
import { styled } from "@material-ui/core/styles";
import Page from "../components/Page";
import { useGetPendingHotelBooking } from "src/Api/Hotel/usecustomApi";
import { getpendinghotelsBooking } from "src/Api/Hotel/ApiUrl";
import LoadingScreen from "src/components/LoadingScreen";
import ErrorPages from "src/components/Error_Pages/ErrorPages";
import PendingBookingList from "src/components/hotelComponent/PendingBookingList";

const ControlBookingStatus = () => {
  const result = useGetPendingHotelBooking(getpendinghotelsBooking);
  const RootStyle = styled(Page)(({ theme }) => ({
    paddingTop: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(11),
    },
  }));
  if (result?.loading) {
    return (
      <RootStyle title="Control Booking Status">
        <LoadingScreen />
      </RootStyle>
    );
  }

  if (result.error) {
    return (
      <RootStyle title="Control Booking Status">
        <ErrorPages title={result.error} />
      </RootStyle>
    );
  }
  return (
    <RootStyle title="Control Booking Status">
      <PendingBookingList data={result?.data?.hotelList} />
    </RootStyle>
  );
};

export default ControlBookingStatus;
