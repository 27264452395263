import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import {
  Box,
  Card,
  FormControl,
  Button,
  MenuItem,
  Typography,
  CardHeader,
  InputLabel,
  Container,
  Select,
  Dialog,
  DialogTitle,
  TextField,
  styled,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Page from "../../Page";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { hostname } from "src/HostName";
import { PATH_DASHBOARD } from "src/routes/paths";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import useSettings from "src/hooks/useSettings";
import { useLottie } from "lottie-react";
import empty from "../../../LottieView/8021-empty-and-lost.json";
import axios from "axios";
import moment from "moment";
import MyAvatar from "src/components/MyAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  faCalendarDays,
  faMoneyBill,
  faMoneyBill1Wave,
} from "@fortawesome/free-solid-svg-icons";
import EditAirportMarkup from "src/components/ModalBody/EditAirportMarkup";
import {
  disableProvider,
  updateFarerule,
} from "src/Api/CallApi/UpdateFarerule";
import { PAX, TripType } from "src/utils/stop";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Tab from "@mui/material/Tab";
import { choiceexcleSiteDownLoad } from "src/utils/excelsheet";
import { Link } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { useSnackbar } from "notistack5";
import LoadingScreen from "src/components/LoadingScreen";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DIV = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    paddingLeft: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export default function AppNewInvoice() {
  const { userId } = useParams();
  const LoginData = localStorage.getItem("LoginData");
  const user = JSON.parse(LoginData);

  const currentUser = userId.split("&")[0].split("=")[1];
  const name = userId.split("&")[1].split("=")[1];

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [ruleData, setRuleData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = useState({});
  const [Airlineopen, setAirlineopen] = useState(false);
  const [airportMarkupAllData, setAirportMarkupAllData] = useState({});
  const [airportMarkup, setAirportMarkup] = useState([]);
  const [airportMarkupIndex, setAirportMarkupIndex] = useState("");
  const [filterApiprovider, setFilterApiprovider] = useState();
  const [bulkAirport, setBulkAirport] = useState({});
  const [bulkdata, setBulkData] = useState();
  const [value, setValue] = useState("1");
  const [active, setActive] = useState();

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const { themeStretch } = useSettings();
  const { state } = useLocation();

  const options = {
    animationData: empty,
    autoPlay: true,
    loop: true,
  };
  const { View } = useLottie(options);

  /* ------------------------------------- Api Call --------------------------------------------- */
  const GetAllRuleMaded = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    // const fetchingRule = await fetch(
    //   `${hostname}get/farerule/info`,
    //   requestOptions`
    // );

    const fetchingRule = await fetch(
      `${hostname}get/farerule/info/${currentUser}`,
      // `${hostname}get/farerule/info/${state.userid}`,
      requestOptions
    );
    const RuleFetchedOverHere = await fetchingRule.json();

    if (RuleFetchedOverHere.status === true) {
      setIsloading(false);
    } else {
      setIsloading(true);
    }
    if (RuleFetchedOverHere) {
      setRuleData(RuleFetchedOverHere);

      const isActive = RuleFetchedOverHere.data.find(
        (item) => item.userid == currentUser
        // (item) => item.userid === state.userid
      );
      setActive(isActive?.isactive === undefined ? true : isActive?.isactive);
      setIsloading(false);
    }
  };
  const buttonBorder = {
    borderRadius: 10,
    border: "1px solid ",
    borderColor: theme.palette.primary.light,
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAirlineClose = () => {
    setAirlineopen(false);
  };

  const handleChangeOpen = (data1, data, id) => {
    const provider = data.apiprovider.filter((item) => item.gdsType !== id);
    setOpen(true);
    setFilterApiprovider(provider);
    setModalData({ ...data1, ...data });
  };

  const ads = {
    name: modalData.name,
    gdsType: modalData.gdsType,
    code: modalData.code === "" ? "ALL" : modalData.code,
    fareType: modalData.fareType === "" ? "ALL" : modalData.fareType,
    airlineMarkup: modalData.airlineMarkup,
    fareTypeMarkup: modalData.fareTypeMarkup,
    blockedAutoTicketAirline: modalData.blockedAutoTicketAirline,
    blockedAirport: modalData.blockedAirport,
    airportMarkup: modalData.airportMarkup,
    supStatus: modalData.supStatus,
    extraMarkup: Number(modalData.extraMarkup),
  };
  const UpdatedFareRuleObj = async (modalData) => {
    const concatednatedArr = filterApiprovider.concat(ads);
    if (concatednatedArr) {
      await updateFarerule(modalData, concatednatedArr, handleClose);
    }
  };
  /* ------------------------------------- Api Call --------------------------------------------- */

  // useEffect(() => GetAllRuleMaded(), [state.userid]);
  useEffect(() => GetAllRuleMaded(), [currentUser]);

  const handleFareObjChange = (e, name) => {
    if (name === "amount" || name === "noOfPAXSource") {
      setModalData({ ...modalData, [name]: Number(e.target.value) });
    } else if (name === "From") {
      setModalData({ ...modalData, [name]: e.target.value });
    } else {
      setModalData({ ...modalData, [name]: e.target.value });
    }
  };

  const handleairportMarkup = (data, data2, proIndex, gdsType) => {
    const provider = data.apiprovider.filter(
      (item) => item.gdsType !== gdsType
    );

    setFilterApiprovider(provider);
    setAirlineopen(true);
    setAirportMarkupAllData(data);
    setAirportMarkup(data2);
    setBulkData(data2);
    setAirportMarkupIndex(proIndex);
  };

  const handleBulkAirport = (e) => {
    e.preventDefault();
    setBulkAirport(e.target.files);
  };

  const AddBulkAirportMarkup = (data) => {
    const form = new FormData();
    form.append("uploadexcel", bulkAirport[0], bulkAirport[0].name);
    // form.append("userid", state.userid);
    form.append("userid", currentUser);
    form.append("gdsType", data.gdsType);

    const options = {
      method: "POST",
      url: `${hostname}add/bulk/airport/markup`,
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
      },
      data: form,
    };

    axios
      .request(options)
      .then(function (response) {
        if (response.data.status === "true") {
          alert(response.data.message);
          handleAirlineClose();
          setBulkAirport({});
        } else {
          alert(response.data.message);
          handleAirlineClose();
          setBulkAirport({});
        }
      })
      .catch(function (error) {
        console.error(error.message);
      });
  };

  const handleDeleteairportMarkup = (data, ind, row, provd, item) => {
    const provider = row.apiprovider.filter(
      (item) => item.gdsType !== provd.gdsType
    );

    var abc = data.filter((it, i) => {
      if (it.ruleName !== item.ruleName) {
        return it;
      }
    });

    const remainData = {
      name: provd.name,
      gdsType: provd.gdsType,
      code: provd.code === "" ? "ALL" : provd.code,
      fareType: provd.fareType === "" ? "ALL" : provd.fareType,
      airlineMarkup: provd.airlineMarkup,
      fareTypeMarkup: provd.fareTypeMarkup,
      blockedAutoTicketAirline: provd.blockedAutoTicketAirline,
      blockedAirport: provd.blockedAirport,
      airportMarkup: abc,
      supStatus: provd.supStatus,
      extraMarkup: Number(provd.extraMarkup),
    };
    const ApiProvider = provider.concat(remainData);
    if (ApiProvider) {
      const Data = {
        _id: row._id,
        provider: ApiProvider,
        blockedAirportSource: row.blockedAirportSource,
        rule: row.rule,
        amount: row.amount,
        fromDate: row.fromDate,
        toDate: row.toDate,
        blockedCountrySource: row.blockedCountrySource,
        noOfPAXSource: row.noOfPAXSource,
        tripTypeSource: row.tripTypeSource,
        convFee: Number(row.convFee),
        isactive: row.isactive,
      };

      const options = {
        method: "PATCH",
        url: `${hostname}update/farerule/info`,
        headers: { "Content-Type": "application/json" },
        data: Data,
      };

      axios
        .request(options)
        .then(function (response) {
          alert("AirportMarkup Rule Deleted Successfully");
        })
        .catch(function (error) {
          console.error("error");
        });
    }
  };
  const handledisableprovider = async (e) => {
    setActive(e.target.checked);
    const singleagent = ruleData.data.find(
      (item) => item.userid == currentUser
      // (item) => item.userid === state.userid
    );
    await disableProvider(singleagent, e.target.checked);
  };

  const [isOpen, setIsOpen] = useState({});
  const toggleOpen = (e, row, provd) => {
    setIsOpen({ [e.target.name]: e.target.checked });

    const provider = row.apiprovider.filter(
      (item) => item.gdsType !== provd.gdsType
    );

    const remainData = {
      name: provd.name,
      gdsType: provd.gdsType,
      code: provd.code === "" ? "ALL" : provd.code,
      fareType: provd.fareType === "" ? "ALL" : provd.fareType,
      airlineMarkup: provd.airlineMarkup,
      fareTypeMarkup: provd.fareTypeMarkup,
      blockedAutoTicketAirline: provd.blockedAutoTicketAirline,
      blockedAirport: provd.blockedAirport,
      airportMarkup: provd.airportMarkup,
      supStatus: e.target.checked ? "Y" : "N",
      extraMarkup: Number(provd.extraMarkup),
    };

    const ApiProvider = provider.concat(remainData);

    if (ApiProvider) {
      const Data = {
        _id: row._id,
        provider: ApiProvider,
        blockedAirportSource: row.blockedAirportSource,
        rule: row.rule,
        amount: row.amount,
        fromDate: row.fromDate,
        toDate: row.toDate,
        blockedCountrySource: row.blockedCountrySource,
        noOfPAXSource: row.noOfPAXSource,
        tripTypeSource: row.tripTypeSource,
        convFee: Number(row.convFee),
        isactive: row.isactive,
      };

      const options = {
        method: "PATCH",
        url: `${hostname}update/farerule/info`,
        headers: { "Content-Type": "application/json" },
        data: Data,
      };

      axios
        .request(options)
        .then(function (response) {
          enqueueSnackbar("Provider Deactive", { variant: "success" });
        })
        .catch(function (error) {
          console.error("error");
          enqueueSnackbar("Their is some technical error", {
            variant: "error",
          });
        });
    }
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : "xxxl"} spacing={1}>
        <HeaderBreadcrumbs
          heading="All Fare Rule"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Applied Rule",
              href: PATH_DASHBOARD.farerule.AppliedRule,
            },
            {
              name: name,
            },
          ]}
        />

        {ruleData.status === true ? (
          <Card style={{ width: "100%" }}>
            <DIV
              style={{ marginBottom: 15, overflow: "hidden" }}
              className="d-flex justify-content-between"
            >
              <CardHeader
                title={name}
                sx={{ mb: 3 }}
                titleTypographyProps={{ variant: "h4" }}
              />
              <Switch
                checked={active}
                onChange={handledisableprovider}
                inputProps={{ "aria-label": "controlled" }}
                className="me-5"
              />
            </DIV>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {ruleData.data

                .filter((item) => item.userid == currentUser)
                .map((row, i) => (
                  <Grid
                    item
                    xs={11}
                    style={{
                      marginLeft: 45,
                      marginBottom: 20,
                      display: "flex",
                      flexDirection: "column",
                      gap: "30px",
                    }}
                  >
                    {row.apiprovider
                      .sort((a, b) => a.gdsType - b.gdsType)
                      .map((provd, proIndex) => (
                        <Grid
                          key={i}
                          className="p-3"
                          style={{
                            borderRadius: 10,
                            border: "1px solid ",
                            borderColor: "#dee1df",
                          }}
                        >
                          <Accordion className="newAccord">
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon className="accordIcon" />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <div
                                className="d-flex align-items-center w-full"
                                style={{
                                  borderBottom: "1px solid rgb(241 241 241)",
                                  borderColor: theme.palette.primary.light,
                                  paddingBottom: "14px",
                                }}
                              >
                                <div className="mr-3 d-flex align-items-center">
                                  <MyAvatar />
                                </div>
                                <div className="d-flex align-items-center justify-content-between w-full">
                                  <div className="d-flex  justify-content-center flex-column mx-1">
                                    <Typography
                                      sx={{
                                        color: "primary.main",
                                        letterSpacing: "1px",
                                        textTransform: "uppercase",
                                      }}
                                      className=" font-bolder"
                                      variant="h6"
                                    >
                                      {provd.name}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      className="text-gray-700 font-bold mb-1"
                                    >
                                      Provider
                                    </Typography>
                                  </div>
                                  <div
                                    className=""
                                    style={{ marginRight: "60px" }}
                                  >
                                    <Switch
                                      defaultChecked={
                                        provd.supStatus === "Y" ? true : false
                                      }
                                      checked={isOpen[provd.name]}
                                      name={provd.name}
                                      onChange={(e) =>
                                        toggleOpen(e, row, provd)
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                      className="mySwitch"
                                    />
                                  </div>
                                </div>
                              </div>
                            </AccordionSummary>

                            <AccordionDetails>
                              <div>
                                <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                                  <FontAwesomeIcon
                                    icon={faMoneyBill}
                                    className="text-gray-400 pe-2 fa-sm"
                                  />
                                  <Typography
                                    className="  text-gray-700 font-bold"
                                    style={{
                                      minWidth: "220px",
                                      fontSize: "17px",
                                      letterSpacing: "0.5px",
                                    }}
                                  >
                                    Amount
                                  </Typography>
                                  <Typography
                                    style={{ fontSize: "17px" }}
                                    className="text-gray-700 font-bold"
                                  >
                                    {row.amount} {row.rule}
                                  </Typography>
                                </div>

                                <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                                  <FontAwesomeIcon
                                    icon={faCalendarDays}
                                    className="text-gray-400 pe-2"
                                  />
                                  <Typography
                                    className="  text-gray-700 font-bold"
                                    style={{
                                      minWidth: "220px",
                                      fontSize: "17px",
                                      letterSpacing: "0.5px",
                                    }}
                                  >
                                    Valid From
                                  </Typography>
                                  <Typography
                                    style={{ fontSize: "17px" }}
                                    className="text-gray-700 font-bold"
                                  >
                                    {moment(row.fromDate).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </Typography>
                                </div>
                                <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                                  <FontAwesomeIcon
                                    icon={faCalendarDays}
                                    className="text-gray-400 pe-2"
                                  />
                                  <Typography
                                    className="  text-gray-700 font-bold"
                                    style={{
                                      minWidth: "220px",
                                      fontSize: "17px",
                                      letterSpacing: "0.5px",
                                    }}
                                  >
                                    Valid To
                                  </Typography>
                                  <Typography
                                    style={{ fontSize: "17px" }}
                                    className="text-gray-700 font-bold"
                                  >
                                    {moment(row.toDate, "DD/MM/YYYY").format(
                                      "MMM DD, YYYY"
                                    )}
                                  </Typography>
                                </div>
                                <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                                  <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                                  <Typography
                                    className="text-gray-700 font-bold"
                                    style={{
                                      fontSize: "17px",
                                      letterSpacing: "0.5px",
                                      minWidth: "220px",
                                    }}
                                  >
                                    Allowed Airlines{" "}
                                  </Typography>
                                  <Typography
                                    style={{ fontSize: "17px" }}
                                    className="text-gray-700 font-bold"
                                  >
                                    {provd.code === "" ? (
                                      "N/A"
                                    ) : (
                                      <>{provd.code}</>
                                    )}
                                  </Typography>
                                </div>
                                <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                                  <FontAwesomeIcon
                                    icon={faMoneyBill1Wave}
                                    className="text-gray-400 pe-2 fa-sm"
                                  />
                                  <Typography
                                    className="  text-gray-700 font-bold"
                                    style={{
                                      minWidth: "220px",
                                      fontSize: "17px",
                                      letterSpacing: "0.5px",
                                    }}
                                  >
                                    Fare type
                                  </Typography>

                                  <Typography
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    {provd.fareType}
                                  </Typography>
                                </div>
                                <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                                  <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                                  <Typography
                                    className="  text-gray-700 font-bold"
                                    style={{
                                      minWidth: "220px",
                                      fontSize: "17px",
                                      letterSpacing: "0.5px",
                                    }}
                                  >
                                    Airline Markup
                                  </Typography>

                                  <Typography
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    {provd.airlineMarkup === "" ? (
                                      "N/A"
                                    ) : (
                                      <>{provd.airlineMarkup}</>
                                    )}
                                  </Typography>
                                </div>

                                <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                                  <FontAwesomeIcon
                                    icon={faMoneyBill1Wave}
                                    className="text-gray-400 pe-2  fa-sm"
                                  />
                                  <Typography
                                    className="  text-gray-700 font-bold"
                                    style={{
                                      minWidth: "220px",
                                      fontSize: "17px",
                                      letterSpacing: "0.5px",
                                    }}
                                  >
                                    Fare Markup
                                  </Typography>
                                  <Typography
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    {provd.fareTypeMarkup == "" ? (
                                      "N/A"
                                    ) : (
                                      <>{provd.fareTypeMarkup}</>
                                    )}
                                  </Typography>
                                </div>
                                <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                                  <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                                  <Typography
                                    className="  text-gray-700 font-bold"
                                    style={{
                                      minWidth: "220px",
                                      fontSize: "17px",
                                      letterSpacing: "0.5px",
                                    }}
                                  >
                                    TripType Source
                                  </Typography>

                                  <Typography>
                                    {row.tripTypeSource == "" ? (
                                      "N/A"
                                    ) : (
                                      <>{row.tripTypeSource}</>
                                    )}
                                  </Typography>
                                </div>
                                <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                                  <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                                  <Typography
                                    className="  text-gray-700 font-bold"
                                    style={{
                                      minWidth: "220px",
                                      fontSize: "17px",
                                      letterSpacing: "0.5px",
                                    }}
                                  >
                                    PAX Source
                                  </Typography>

                                  <Typography>
                                    {row.noOfPAXSource === 0 ? (
                                      "Any"
                                    ) : (
                                      <>{row.noOfPAXSource}</>
                                    )}
                                  </Typography>
                                </div>
                                <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                                  <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                                  <Typography
                                    className="  text-gray-700 font-bold"
                                    style={{
                                      minWidth: "220px",
                                      fontSize: "17px",
                                      letterSpacing: "0.5px",
                                    }}
                                  >
                                    BlockCountrySource
                                  </Typography>

                                  <Typography
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    {row.blockedCountrySource == "" ? (
                                      "N/A"
                                    ) : (
                                      <>{row.blockedCountrySource}</>
                                    )}
                                  </Typography>
                                </div>
                                <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                                  <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                                  <Typography
                                    className="text-gray-700 font-bold"
                                    style={{
                                      fontSize: "17px",
                                      letterSpacing: "0.5px",
                                      minWidth: "220px",
                                    }}
                                  >
                                    BlockAirportSource{" "}
                                  </Typography>
                                  <Typography
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    {row.blockedAirportSource === "" ? (
                                      "N/A"
                                    ) : (
                                      <>{row.blockedAirportSource}</>
                                    )}
                                  </Typography>
                                </div>
                                <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                                  <i class="fas fa-plane-departure fa-xs text-gray-400 pe-2"></i>
                                  <Typography
                                    className="text-gray-700 font-bold"
                                    style={{
                                      fontSize: "17px",
                                      letterSpacing: "0.6px",
                                      minWidth: "220px",
                                    }}
                                  >
                                    Allowed Airport
                                  </Typography>
                                  <Typography
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    {provd.blockedAirport}
                                  </Typography>
                                </div>
                                <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                                  <FontAwesomeIcon
                                    icon={faMoneyBill}
                                    className="text-gray-400 pe-2 fa-sm"
                                  />
                                  <Typography
                                    className="  text-gray-700 font-bold"
                                    style={{
                                      minWidth: "220px",
                                      fontSize: "17px",
                                      letterSpacing: "0.5px",
                                    }}
                                  >
                                    extraMarkupAmount
                                  </Typography>
                                  <Typography
                                    style={{ fontSize: "17px" }}
                                    className="text-gray-700 font-bold"
                                  >
                                    {provd.extraMarkup}
                                  </Typography>
                                </div>
                                <div className="d-flex align-items-center mb-3 pb-3 border-bottom emptycell-none">
                                  <FontAwesomeIcon
                                    icon={faMoneyBill}
                                    className="text-gray-400 pe-2 fa-sm"
                                  />
                                  <Typography
                                    className="  text-gray-700 font-bold"
                                    style={{
                                      minWidth: "220px",
                                      fontSize: "17px",
                                      letterSpacing: "0.5px",
                                    }}
                                  >
                                    Convenience fee
                                  </Typography>
                                  <Typography
                                    style={{ fontSize: "17px" }}
                                    className="text-gray-700 font-bold"
                                  >
                                    {row.convFee}
                                  </Typography>
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <div>
                            <div className="d-flex align-items-center justify-content-between">
                              <Typography
                                className="pe-4 mb-4 mt-4 text-gray-700 font-bolder"
                                style={{
                                  fontSize: "20px",
                                  letterSpacing: "0.5px",
                                  textDecoration: "underline",
                                  margin: "0",
                                  fontWeight: "600",
                                }}
                              >
                                Airport Markup
                              </Typography>
                              <div>
                                <Link
                                  to={`/dashboard/flightrule/user=${currentUser}&gds=${provd.gdsType}&name=${name}`}
                                  state={{
                                    gdsType: provd.gdsType,
                                    userid: currentUser,

                                    name: name,
                                  }}
                                  className="text-decoration-none"
                                >
                                  <Button style={buttonBorder} className="me-3">
                                    Full Screen
                                  </Button>
                                </Link>

                                <Button
                                  onClick={() =>
                                    choiceexcleSiteDownLoad(
                                      provd.airportMarkup,
                                      user
                                    )
                                  }
                                  style={buttonBorder}
                                  className="me-3"
                                >
                                  Download{" "}
                                  <ArrowDownwardIcon className="ml-2 " />
                                </Button>
                                <Button
                                  onClick={() => {
                                    handleairportMarkup(
                                      row,
                                      provd,
                                      proIndex,
                                      provd.gdsType
                                    );
                                  }}
                                  style={buttonBorder}
                                >
                                  Add <AddIcon />
                                </Button>
                              </div>
                            </div>
                            <div>
                              <TableContainer className="tableairp-responsive">
                                <Table className="table-airp">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Rule Name</TableCell>
                                      <TableCell>TripType</TableCell>
                                      <TableCell>CabinClass</TableCell>
                                      <TableCell>
                                        CabinSeg. &nbsp;/ &nbsp;CabinInbound
                                      </TableCell>
                                      <TableCell>
                                        ArrTimeMrkp &nbsp;/ &nbsp;DepTimeMrkp
                                      </TableCell>
                                      <TableCell>Origin</TableCell>
                                      <TableCell>Destination</TableCell>
                                      <TableCell>OutBound Start-End</TableCell>
                                      <TableCell>Airline</TableCell>
                                      <TableCell>flightNumber</TableCell>
                                      <TableCell>flightNoReturn</TableCell>
                                      <TableCell>Amount</TableCell>
                                      <TableCell>CapAmount</TableCell>
                                      <TableCell>Ori.Ctry</TableCell>
                                      <TableCell>Dest.Ctry</TableCell>
                                      <TableCell>MinAmount</TableCell>
                                      <TableCell>MaxAmount</TableCell>
                                      <TableCell>CappingDays</TableCell>
                                      <TableCell>Stops</TableCell>
                                      <TableCell>PAX</TableCell>
                                      <TableCell>OpaData</TableCell>
                                      <TableCell>InBound Start-End</TableCell>
                                      <TableCell>FareType</TableCell>
                                      <TableCell>DayGap</TableCell>
                                      <TableCell>DayGap End</TableCell>
                                      <TableCell>Pure Operated</TableCell>
                                      <TableCell>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {console.log("user", user)}
                                  {provd.airportMarkup === undefined ? (
                                    []
                                  ) : (
                                    <TableBody>
                                      {user.role === "admin"
                                        ? provd.airportMarkup
                                            .slice(0, 3)
                                            .map((item, ind) => {
                                              return (
                                                <TableRow key={ind}>
                                                  <TableCell>
                                                    {item.ruleName}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.tripType === undefined
                                                      ? null
                                                      : item.tripType === "1"
                                                      ? 1
                                                      : item.tripType === "2"
                                                      ? 2
                                                      : "Both"}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.cabinClass}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.cabinSegment} &nbsp;/
                                                    &nbsp;
                                                    {item.cabinSegmentInbound}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.arrTimeMarkup} &nbsp;/
                                                    &nbsp;
                                                    {item.depTimeMarkup}
                                                  </TableCell>
                                                  <TableCell className="maxw-eliptr">
                                                    {item.From}
                                                  </TableCell>
                                                  <TableCell className="maxw-eliptr">
                                                    {item.To}
                                                  </TableCell>
                                                  <TableCell>
                                                    {item.RuleDateFrom}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.AirCode}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.flightNo}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.flightNoReturn}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.Amount}&nbsp;
                                                    {item.markupType}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.capAmount ===
                                                    undefined ? (
                                                      "N/A"
                                                    ) : (
                                                      <>{item.capAmount}</>
                                                    )}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.originCountry}
                                                  </TableCell>{" "}
                                                  <TableCell className="text-center">
                                                    {item.destinationCountry}
                                                  </TableCell>{" "}
                                                  <TableCell className="text-center">
                                                    {item.minAmount}
                                                  </TableCell>{" "}
                                                  <TableCell className="text-center">
                                                    {item.maxAmount}
                                                  </TableCell>{" "}
                                                  <TableCell className="text-center">
                                                    {item.cappingDays}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.stops === -1 ? (
                                                      "ALL"
                                                    ) : (
                                                      <>{item.stops}</>
                                                    )}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.noOfPAX}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.opaqueData}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.RuleDateFromIn}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.FareType}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.dGap}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.dGapEnd}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.operatedBy}
                                                  </TableCell>
                                                  <TableCell>
                                                    <Button
                                                      onClick={() =>
                                                        handleDeleteairportMarkup(
                                                          provd.airportMarkup,
                                                          ind,
                                                          row,
                                                          provd,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Delete
                                                    </Button>
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            })
                                        : provd.airportMarkup
                                            .filter(
                                              (agen, i) =>
                                                agen.userid == user.userid
                                            )
                                            .slice(0, 3)
                                            .map((item, ind) => {
                                              return (
                                                <TableRow key={ind}>
                                                  <TableCell>
                                                    {item.ruleName}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.tripType === undefined
                                                      ? null
                                                      : item.tripType === "1"
                                                      ? 1
                                                      : item.tripType === "2"
                                                      ? 2
                                                      : "Both"}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.cabinClass}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.cabinSegment} &nbsp;/
                                                    &nbsp;
                                                    {item.cabinSegmentInbound}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.arrTimeMarkup} &nbsp;/
                                                    &nbsp;
                                                    {item.depTimeMarkup}
                                                  </TableCell>
                                                  <TableCell className="maxw-eliptr">
                                                    {item.From}
                                                  </TableCell>
                                                  <TableCell className="maxw-eliptr">
                                                    {item.To}
                                                  </TableCell>
                                                  <TableCell>
                                                    {item.RuleDateFrom}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.AirCode}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.flightNo}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.flightNoReturn}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.Amount}&nbsp;
                                                    {item.markupType}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.capAmount ===
                                                    undefined ? (
                                                      "N/A"
                                                    ) : (
                                                      <>{item.capAmount}</>
                                                    )}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.originCountry}
                                                  </TableCell>{" "}
                                                  <TableCell className="text-center">
                                                    {item.destinationCountry}
                                                  </TableCell>{" "}
                                                  <TableCell className="text-center">
                                                    {item.minAmount}
                                                  </TableCell>{" "}
                                                  <TableCell className="text-center">
                                                    {item.maxAmount}
                                                  </TableCell>{" "}
                                                  <TableCell className="text-center">
                                                    {item.cappingDays}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.stops === -1 ? (
                                                      "ALL"
                                                    ) : (
                                                      <>{item.stops}</>
                                                    )}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.noOfPAX}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.opaqueData}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.RuleDateFromIn}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.FareType}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.dGap}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.dGapEnd}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {item.operatedBy}
                                                  </TableCell>
                                                  <TableCell>
                                                    <Button
                                                      onClick={() =>
                                                        handleDeleteairportMarkup(
                                                          provd.airportMarkup,
                                                          ind,
                                                          row,
                                                          provd,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Delete
                                                    </Button>
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            })}
                                    </TableBody>
                                  )}
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                          <div className="text-right">
                            <Button
                              variant="contained"
                              color="warning"
                              onClick={() => {
                                handleChangeOpen(provd, row, provd.gdsType);
                              }}
                            >
                              Edit
                            </Button>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                ))}
            </Grid>

            <Dialog
              fullWidth={"600px"}
              onClose={handleAirlineClose}
              open={Airlineopen}
            >
              <Box>
                <TabContext value={value}>
                  <Box
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                    className="d-flex justify-content-between m-4"
                  >
                    <TabList onChange={handleChangeTab}>
                      <Tab label="Add Mannual" value="1" />
                      <Tab label="Add Bulk" value="2" />
                    </TabList>

                    <CloseIcon
                      onClick={handleAirlineClose}
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                    />
                  </Box>
                  <TabPanel value="1" className="mx-4">
                    <EditAirportMarkup
                      airportMarkup={airportMarkup}
                      data={airportMarkupAllData}
                      providerIndex={airportMarkupIndex}
                      filterApiprovider={filterApiprovider}
                      handleAirlineClose={handleAirlineClose}
                    />
                  </TabPanel>
                  <TabPanel value="2" className="mx-4">
                    <div className="d-flex flex-column justify-content-center ">
                      <Input
                        type="file"
                        placeholder="Upload BulkAirportMarkup"
                        name="bulkAirport"
                        id="bulkAirport"
                        sx={{ width: "300px", marginRight: "20px" }}
                        onChange={handleBulkAirport}
                      />
                      <div className="text-center">
                        <Button
                          variant="contained"
                          sx={{
                            marginBottom: "20px",
                            marginTop: 2,
                            height: "45px",
                            fontSize: "16px",
                            width: "140px",
                          }}
                          disabled={
                            Object.keys(bulkAirport).length !== 0 ? false : true
                          }
                          onClick={() => {
                            AddBulkAirportMarkup(bulkdata);
                          }}
                        >
                          Sumbit
                        </Button>
                      </div>
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            </Dialog>

            <Dialog
              fullWidth={true}
              onClose={handleClose}
              open={open}
              fullScreen={true}
              sx={{ margin: "40px" }}
              style={{ borderRadius: 10 }}
            >
              <DialogTitle sx={{ marginBottom: "20px" }}>
                Edit Fare Rule{" "}
              </DialogTitle>
              <DialogContent>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="fromDate"
                        type=""
                        value={modalData.fromDate}
                        id="fromDate"
                        label="From Date (MM/DD/YYYY)"
                        onChange={(e) => handleFareObjChange(e, "fromDate")}
                        sx={{ margin: 1, width: "100%" }}
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="toDate"
                        value={modalData.toDate}
                        type=""
                        id="toDate"
                        label="To Date (MM/DD/YYYY)"
                        onChange={(e) => handleFareObjChange(e, "toDate")}
                        sx={{ margin: 1, width: "100%" }}
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={6} md={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "60px",
                          paddingLeft: "10px",
                        }}
                      >
                        <label
                          style={{
                            marginRight: "30px",
                          }}
                        >
                          Markup Type
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <input
                            name="fareType"
                            type="radio"
                            value={"%"}
                            checked={modalData.rule === "%"}
                            id="farerType"
                            onChange={(e) => handleFareObjChange(e, "rule")}
                            style={{
                              height: "18px",
                              width: "18px",
                              marginRight: "15px",
                            }}
                          />
                          <label style={{ marginRight: "35px" }}>%</label>
                          <input
                            name="fareType"
                            type="radio"
                            checked={modalData.rule === "FLAT"}
                            value={"FLAT"}
                            id="fareType"
                            placeholder="Fare Type"
                            onChange={(e) => handleFareObjChange(e, "rule")}
                            style={{
                              height: "18px",
                              width: "18px",
                              marginRight: "15px",
                            }}
                          />
                          <label>FLAT</label>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        value={modalData.amount}
                        name="amount"
                        id="amount"
                        placeholder="Provider"
                        sx={{ margin: 1, width: "100%" }}
                        onChange={(e) => handleFareObjChange(e, "amount")}
                        label="Amount"
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        value={modalData.name}
                        name="name"
                        id="name"
                        disabled={true}
                        placeholder="Provider"
                        sx={{ margin: 1, width: "100%" }}
                        onChange={(e) => handleFareObjChange(e, "name")}
                        label="Supplier"
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="fareType"
                        value={modalData.fareType}
                        id="farerType"
                        placeholder="Fare Type"
                        onChange={(e) => handleFareObjChange(e, "fareType")}
                        sx={{ margin: 1, width: "100%" }}
                        label=" Fare Type (Published,Regular)"
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="fareTypeMarkup"
                        value={modalData.fareTypeMarkup}
                        id="fareTypeMarkup"
                        placeholder="Fare Type Markup (PUBLISHED:199,Regular:299,SMEFares:399)"
                        onChange={(e) =>
                          handleFareObjChange(e, "fareTypeMarkup")
                        }
                        sx={{ margin: 1, width: "100%" }}
                        label="Fare Type Markup (PUBLISHED:199,Regular:299,SMEFares:399)"
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="code"
                        value={modalData.code}
                        id="code"
                        placeholder="Airline Code (AI,6E ,G8)"
                        onChange={(e) => handleFareObjChange(e, "code")}
                        sx={{ margin: 1, width: "100%" }}
                        label="  Allowed Airline Codes (ALL,AI,6E)"
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="airlineMarkup"
                        value={modalData.airlineMarkup}
                        id="airlineMarkup"
                        placeholder="Airline Markup (6E:400,G8:50,UK:99)"
                        onChange={(e) =>
                          handleFareObjChange(e, "airlineMarkup")
                        }
                        sx={{ margin: 1, width: "100%" }}
                        label=" Airline Markup (6E:400,G8:50,UK:99)"
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="blockedAirportSource"
                        value={modalData.blockedAirportSource}
                        id="blockedAirportSource"
                        placeholder="blockedAirportSource"
                        onChange={(e) =>
                          handleFareObjChange(e, "blockedAirportSource")
                        }
                        sx={{ margin: 1, width: "100%" }}
                        label=" blockedAirportSource"
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={2} className=" align-items-center ">
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="blockedCountrySource"
                        value={modalData.blockedCountrySource}
                        id="blockedCountrySource"
                        placeholder="blockedCountrySource"
                        onChange={(e) =>
                          handleFareObjChange(e, "blockedCountrySource")
                        }
                        sx={{ margin: 1, width: "100%" }}
                        label="blockedCountrySource"
                        variant="filled"
                      />
                    </Grid>
                    <Grid
                      className="d-flex align-items-center "
                      item
                      xs={12}
                      xl={6}
                      md={6}
                    >
                      <Grid>
                        <FormControl
                          variant="filled"
                          style={{ width: "250px" }}
                          className="me-5"
                        >
                          <InputLabel id="tripType">Select TripType</InputLabel>
                          <Select
                            labelId="tripTypeSource"
                            id="tripTypeSource"
                            name="tripTypeSource"
                            value={modalData.tripTypeSource}
                            sx={{ margin: 1, width: "100%" }}
                            onChange={(e) =>
                              handleFareObjChange(e, "tripTypeSource")
                            }
                          >
                            {TripType.map((item) => (
                              <MenuItem value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid>
                        <FormControl
                          variant="filled"
                          style={{ width: "250px" }}
                        >
                          <InputLabel id="noOfPAX">Select No of PAX</InputLabel>
                          <Select
                            labelId="noOfPAXSource"
                            id="noOfPAXSource"
                            name="noOfPAXSource"
                            value={modalData.noOfPAXSource}
                            sx={{ margin: 1, width: "100%" }}
                            onChange={(e) =>
                              handleFareObjChange(e, "noOfPAXSource")
                            }
                          >
                            {PAX.map((item) => (
                              <MenuItem value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="blockedAirport"
                        value={modalData.blockedAirport}
                        id="blockedAirport"
                        placeholder="AllowedAirport"
                        onChange={(e) =>
                          handleFareObjChange(e, "blockedAirport")
                        }
                        sx={{ margin: 1, width: "100%" }}
                        label="AllowedAirport"
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        value={modalData.extraMarkup}
                        name="extraMarkup"
                        id="extraMarkup"
                        placeholder="ExtraMarkup (Flat)"
                        sx={{ margin: 1, width: "100%" }}
                        onChange={(e) => handleFareObjChange(e, "extraMarkup")}
                        label="ExtraMarkup (Flat)"
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={6} md={6}>
                      <TextField
                        name="convFee"
                        value={modalData.convFee}
                        id="convFee"
                        placeholder="convenience fee"
                        onChange={(e) => handleFareObjChange(e, "convFee")}
                        sx={{ margin: 1, width: "100%" }}
                        label="convenience fee"
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <div className="text-center">
                <Button
                  variant="contained"
                  onClick={() => UpdatedFareRuleObj(modalData)}
                  sx={{
                    marginBottom: "20px",
                    marginTop: 2,
                    height: "45px",
                    fontSize: "16px",
                    width: "140px",
                  }}
                >
                  Submit
                </Button>
              </div>
            </Dialog>
          </Card>
        ) : (
          <div>
            {View}
            No fareRule Applied
          </div>
        )}
      </Container>
    </Page>
  );
}
