// export const hostname = "http://10.20.1.73:6500/"; //local
// export const hostname = "http://10.20.1.73:10000/"; //local
// export const hostname = "http://10.20.1.34:7000/";
export const hostname = "http://www.traveloes.com:6500/"; //live

// Hotel Database
// export const hotelhostname = "http://10.20.1.73:2800"; //local
export const hotelhostname = "https://hotelapi.travomint.com"; //live
// export const FlightBookingHostname = "http://10.20.1.32:1001";            //local

export const flightHost = "http://13.127.172.138:3001/"; //live

// Pradeep Sir Flight
export const flightHostLive = "http://44.224.252.119:3001/"; //live
// export const flightHostLive = "http://10.20.1.57:1001/"; //local

// Scrapping Live Database
export const scrapping_Host = "http://44.224.252.119:2500/"; //live
// export const scrapping_Host = "http://10.20.1.73:2500/";                //local
