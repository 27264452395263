// hooks
import useAuth from "../hooks/useAuth";
//
import { MAvatar } from "./@material-extend";
import createAvatar from "../utils/createAvatar";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  // const { user } = useAuth();
  const LoginData = localStorage.getItem("LoginData");
  const user = JSON.parse(LoginData);

  return (
    <MAvatar
      // src={user.photoURL}
      // alt={user.displayName}
      // color={user.photoURL ? "default" : createAvatar(user.displayName).color}
      {...other}
    >
      {/* {createAvatar(user.displayName).name} */}
    </MAvatar>
  );
}
