import { createSlice } from "@reduxjs/toolkit";
import { sum, map, filter, uniqBy, reject } from "lodash";

const initialState = {
  removeEntry: [],
};

const deleteRule = createSlice({
  name: "RemoveRule",
  initialState,
  reducers: {
    datafromTable(state, action) {

      state.removeEntry = action.payload;
    },
    removeRuleFromTable(state, action) {
  
      state.removeEntry = reject(state.removeEntry, { userid: action.payload });
    },
  },
});
export const { removeRuleFromTable, datafromTable } = deleteRule.actions;

export default deleteRule.reducer;
/* reducers: {
    removeRuleFromTable(state, action) {
      state.removeEntry != action.payload;
    },
  }, */
