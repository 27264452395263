import PropTypes from "prop-types";
// material
import { useTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import flashFill from "@iconify/icons-eva/flash-fill";
import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Button, Link, Container, Typography, Stack } from "@material-ui/core";
// routes
// import { PATH_DASHBOARD } from "../../../routes/paths";S
//
import {
  varFadeIn,
  varFadeInUp,
  varWrapEnter,
  varFadeInRight,
} from "../components/animate";
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};
const HeroOverlayStyle = styled(motion.img)({
  zIndex: 9,
  width: "10%",
  height: "50%",
  objectFit: "contain",
  position: "absolute",
  bottom: "25 %",
});
export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <>
      <Box sx={{ width: 150, height: 20, ...sx }}>
        <img src="/static/brand/Travellogo.png" class="img" alt="login" />
      </Box>
    </>
  );
}
